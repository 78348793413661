 /deep/ .el-radio-group {
  display: inline-block;
}
 /deep/ .el-radio-group .el-radio {
  margin: 0 20px 0 0;
}
.addlist .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.roletree .el-checkbox__input.is-checked .el-checkbox__inner,
.roletree .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.roletree .custom-tree-node {
  width: 100%;
}
.roletree .el-tree-node__content {
  height: 100%;
  border-bottom: 1px solid #ccc;
}
.roletree .el-tree-node__content .el-checkbox {
  margin-left: 10px;
}
.roletree .el-tree {
  border: 1px solid #ccc;
  border-bottom: 0;
}
.roletree .el-tree > div > .el-tree-node__content {
  background-color: #eee;
  height: 30px;
  padding-left: 10px;
}
.roletree .el-tree > div > .el-tree-node__children .el-tree-node {
  background-color: #fff;
}
.roletree .el-tree > div > .el-tree-node__children .el-tree-node .el-tree-node__content {
  padding: 10px 0 10px 18px;
}
.roletree .el-tree > div > .el-tree-node__children .el-tree-node .el-tree-node__content .level2-parent {
  padding-right: 20px;
  margin-right: 5px;
}
.roletree .el-tree-node__expand-icon {
  display: none;
}
